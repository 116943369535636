import { useMemo } from "react";
import useNewDataFetcher from "../../../../Hooks/newUseDataFetcher";
import GraphTable from "../../../../Components/GraphTable";
import SubheaderTimePeriod from "../../../../Components/SubheaderTimePeriod";
import { formatNumber } from "../../../../Utilities/formatting";

function SalesScoreboard() {
  const dataURL = "/api/v1/result/bookings/sales-scoreboard"
  const { data, dateRange, filterTypes, filters, isReady } = useNewDataFetcher({
    endPoint: dataURL,
  });

  const currentLeader = useMemo(() => {
    let leader = { name: 'No Leader', revenue: 0 };
    if (!data) return leader;
    for (let user of data.data) {
      if (user.revenue === leader.revenue) {
        leader.name += ` and ${user.name}`;
      } else if (user.revenue > leader.revenue) {
        leader.name = user.name;
        leader.revenue = user.revenue;
      }
    }
    return leader;
  }, [data]);

  return (
    <GraphTable data={data} dateRange={dateRange} interval={dateRange.interval} filterTypes={filterTypes} filters={filters} isReady={isReady} dataURL={dataURL}>
      <h1 className="page-heading">Revenue Scoreboard</h1>
      <h2 className="page-subheading">
        Current Leader{currentLeader.name.includes(' and ') ? 's' : ''}: <span className="page-data">{currentLeader.name}</span> with <span className="page-data">${formatNumber(currentLeader.revenue)}</span> booked
        <SubheaderTimePeriod data={dateRange} />
      </h2>
    </GraphTable>
  );

}

export default SalesScoreboard;
