import { Routes, Route, Navigate } from "react-router-dom";
import PageNav from "../../Components/PageNav";
import SalesScoreboard from "./SubPages/Sales";
import RecruitingScoreboard from "./SubPages/Recruiting";
import "./style.scss";

function Bookings() {
  return (
    <>
      <PageNav
        title="Revenue Scoreboard"
        links={[
          { label: "Sales", href: "sales" },
          { label: "Recruiting", href: "recruiting" }
        ]}
      />
      <div className="page">
        <Routes>
          <Route path={`sales`} element={<SalesScoreboard />} />
          <Route path={`recruiting`} element={<RecruitingScoreboard />} />
          <Route index element={<Navigate to={`sales`} />} />
        </Routes>
      </div>
    </>
  );
}

export default Bookings;
